import { useSupabase } from './use-supabase';
import useSWRMutation from 'swr/mutation';

interface Credentials {
  email: string;
  password: string;
}

interface UseSignUpWithEmailAndPasswordOptions {
  nextPath: string;
  callbackPath: string;
}

export function useSignUpWithEmailAndPassword({
  nextPath,
  callbackPath,
}: UseSignUpWithEmailAndPasswordOptions) {
  const client = useSupabase();
  const key = ['auth', 'sign-up-with-email-password'];

  return useSWRMutation(
    key,
    (_, { arg: credentials }: { arg: Credentials }) => {
      const emailRedirectTo = getRedirectUrl(nextPath, callbackPath);

      return client.auth
        .signUp({
          ...credentials,
          options: {
            emailRedirectTo,
          },
        })
        .then((response) => {
          if (response.error) {
            throw response.error.message;
          }

          const user = response.data?.user;
          const identities = user?.identities ?? [];

          // if the user has no identities, it means that the email is taken
          if (identities.length === 0) {
            throw new Error('User already registered');
          }

          return response.data;
        });
    }
  );
}

function getRedirectUrl(nextPath: string, callbackPath: string) {
  const fullPath = `${callbackPath}?next=${nextPath}`;

  return new URL(fullPath, window.location.origin).href;
}
