import type { VerifyOtpParams } from '@supabase/gotrue-js';
import { useSupabase } from './use-supabase';
import useMutation from 'swr/mutation';

export function useVerifyOtp() {
  const client = useSupabase();

  return useMutation(
    ['verify-otp'],
    async (_, { arg }: { arg: VerifyOtpParams }) => {
      const { data, error } = await client.auth.verifyOtp(arg);

      if (error) {
        throw error;
      }

      return data;
    }
  );
}
