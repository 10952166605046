import { useSupabase } from './use-supabase';
import { useCallback } from 'react';

export function useSignOut() {
  const client = useSupabase();

  return useCallback(async () => {
    await client.auth.signOut();
  }, [client.auth]);
}
