import { useSupabase } from './use-supabase';
// import { useRouter } from 'next/navigation';
import useSWR from 'swr';

export function useAuthUser() {
  // const router = useRouter();
  const client = useSupabase();
  const key = 'user';

  return useSWR([key], async () => {
    return client.auth
      .getUser()
      .then((result) => {
        if (result.error) {
          return Promise.reject(result.error);
        }

        if (result.data?.user) {
          return result.data.user;
        }

        return Promise.reject('Unexpected result format');
      })
      .catch(() => {
        // FIXME
        // return router.refresh();
      });
  });
}
