import Alert from '~/core/ui/Alert';
import Trans from '~/core/ui/Trans';

/**
 * @name AuthErrorMessage
 * @param error This error comes from Supabase as the code returned on errors
 * This error is mapped from the translation auth:errors.{error}
 * To update the error messages, please update the translation file
 * https://github.com/supabase/gotrue-js/blob/master/src/lib/errors.ts
 * @constructor
 */
export default function AuthErrorMessage({
  error,
}: {
  error: Maybe<Error | unknown>;
}) {
  if (!error) {
    return null;
  }

  const errorCode =
    typeof error === 'object' && 'message' in error ? error.message : error;

  return (
    <Alert className={'w-full'} type={'error'}>
      <Alert.Heading>
        <Trans i18nKey={'auth:errorAlertHeading'} />
      </Alert.Heading>

      <p className={'text-sm font-medium'} data-cy={'auth-error-message'}>
        {String(errorCode)}
      </p>
    </Alert>
  );
}
