import useMutation from 'swr/mutation';
import type { SignInWithPasswordlessCredentials } from '@supabase/gotrue-js';
import { useSupabase } from './use-supabase';

export function useSignInWithOtp() {
  const client = useSupabase();
  const key = ['auth', 'sign-in-with-otp'];

  return useMutation(
    key,
    async (
      _,
      {
        arg: credentials,
      }: {
        arg: SignInWithPasswordlessCredentials;
      }
    ) => {
      const result = await client.auth.signInWithOtp(credentials);

      if (result.error) {
        throw result.error.message;
      }

      return result.data;
    }
  );
}
